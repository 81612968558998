import {Button} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useViewport from './useViewport';
import ArrowRightSmall from '../../images/ArrowRightSmall.svg'
import PlusComponent from "../../ui/plusComponent";
import {Link} from "gatsby";
import { ContainerL } from "../../ui/containers";

const SliderContainer = styled.div`
  position: relative;
  top: ${props => props.height < 437 ? 30 : 50}px;
  width: 100%;
  max-width: ${props => props.maxWidth}px;
  min-height: ${props => 750}px;
  background: rgb(11, 10, 10);
  @media (min-width: ${props => props.maxWidth}px) {
    margin: 0 auto;
  }
`

const SliderItems = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  background: rgb(11, 10, 10);
  align-items: center;
  position: relative;
  min-height: inherit
`

const SliderImage = styled.img`
  width: ${props => props.width || 620}px;
  height: ${props => props.height ? props.height < 300 ? 300 : props.height : 447}px;
  transition: transform 0.5s, right 0.5s, opacity 0.5s;
  margin: 0px 90px;
  border-radius: 10px;
  position: absolute;
  @media (max-width: 991px) {
    margin: 0px;
    width: 100%;
    height: auto;
    opacity: 1 !important;
    transform: none !important;
  }
  @media (max-width: 500px) {
    height: 250px;
  }
`

const SliderItem = styled.div`
  transition: transform 0.5s, right 0.5s, opacity 0.5s;
  width: ${props => props.width || 620}px;
  height: ${props => props.height || 447}px;
  position: absolute;
  @media (max-width: 991px) {
    margin: 0px;
    width: 90%;
    bottom: 200px;
  }
`


const HideArrowDiv = styled.div`
  zIndex: 5;
  position: absolute;
  left: ${props => props.direction === "left" ? -15 + "px" : "none"};
  right: ${props => props.direction === "right" ? -15 + "px" : "none"};
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.25);
  @media (max-width: 991px) {
    display: none;
  }
`

const RadioControl = styled.div`
  margin: 35px auto;
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 10px 0px 50px;
`

const RadioDiv = styled.div`
  cursor: pointer;
  width: 10px;
  height: 10px;
  box-shadow: ${props => props.selected ? "0px 0px 8px 2px rgba(66, 133, 244, 0.2)" : "0px 0px 8px 2px rgba(0, 0, 0, 0.2)"};
  background: ${props => props.selected ? "#4285F4" : "#373333"};
`

const TestDiv = styled.div`
  position: absolute;
  right: ${props => props.width > 600 ? props.minDx - 180 - 34 : props.width < 400 ? 220 : props.width < 450 ? 205 : props.width < 500 ? 195 : props.width < 550 ? 190 : props.width < 600 ? 190 : 170}px;
  width: ${props => (props.width < 500 ? 30 : 0) + props.width * 1.4 + props.width * 0.645 - props.width * 0.645 * 0.738 / 2}px;
  display: flex;
  align-items: center;
  z-index: 100;
`

const LineHelper = styled.div`
  position: absolute;
  left: 0px;
  width: ${props => props.width}px;
  display: flex;
  align-items: center;
  z-index: 100;
`

const InfoCard = styled.div`
  width: ${props => props.width || 400}px;
  min-width: 255px;
  min-height: ${props => props.height || 447}px;
  position: absolute;
  left: ${props => -props.width * 0.728}px;
  background: #1C1A1A;
  color: white;
  border-radius: 16px;
  z-index: 10;
  padding: ${props => props.width < 250 ? "28px 18px" : props.width < 365 ? "28px 20px 28px 70px" : "84px 70px"};
  @media (max-width: 991px) {
    width: 255px;
    min-width: 255px;
    height: 290px;
    position: absolute;
    left: 15px;
    top: -260px;
    background: #1C1A1A;
    color: white;
    border-radius: 16px;
    padding: 60px 18px;
    z-index: 10;
  }
`

const InfoCardTitle = styled.div`
  font-size: ${props => props.width < 300 ? "18" : "22"}px;
  font-weight: 500;
  padding-bottom: ${props => props.width < 250 ? "0" : "10"}px;
`

const InfoCardLine = styled.div`
  font-size: ${props => props.width < 250 ? "15" : "18"}px;
  margin: ${props => props.width < 300 ? "15px 0" : "36px 0"};
  font-weight: 300;
`

const InfoCardLink = styled(Link)`
  display: flex;
  width: fit-content;
  border-color: #1C1A1A;
  transition: 0.1s border-color;
  color: white;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid #4285F4;
    border-radius: 2px;
    text-decoration: none !important;
  }
`

const SliderNavLeft = styled.div`
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 8px;
  z-index: 3;

  :hover {
    background: white;
    border-color: white;
  }
`

const SliderNavRight = styled.div`
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 8px;
  z-index: 3;

  :hover {
    background: white;
    border-color: white;
  }
`

const NavButtonStyles = {
    width: "68px",
    height: "68px",
    paddingTop: "13px",
    borderRadius: "8px",
    background: "inherit",
    borderColor: "inherit",
    zIndex: 5
}


const calcMinDx = width => {
    return 380;
}
let minDx = 380;
const CarouselProjects = ({maxWidth = 2000, items}) => {
    const screens = useBreakpoint();

    let dx = 1080;


    const renderSlider = () => {
        sliderRef.current.childNodes.forEach((el, count) => {
            let width = window.innerWidth;
            if (width > maxWidth) width = maxWidth;
            if (el.id === "test") return;
            minDx = calcMinDx(width)

            if (width < 992) {
                dx *= 2;
                el.style = `right: ${count === selectedItem ? width * 0.1 / 2 : dx * selectedItem - dx * count}px; ${count === selectedItem ? 'z-index:2' : ''}`;
                el.childNodes[1].style = `${'opacity: 1;'}`;
            } else {
                el.style = `right: ${(dx * selectedItem - dx * count) + minDx}px;}`;
                el.childNodes[1].style = `${count === selectedItem ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
            }
        })
    }

    useEffect(() => {
        sliderRef.current.childNodes.forEach((el, count) => {
            let width = window.innerWidth;
            if (width > maxWidth) width = maxWidth;
            if (el.id === "test") return;

            minDx = calcMinDx(width);

            if (width < 992) {
                el.style = `right: ${count === selectedItem ? width * 0.1 / 2 : 1200 * selectedItem - 1200 * count}px; ${count === selectedItem ? 'z-index:2' : ''}`;
            } else {
                el.style = `right: ${(dx * selectedItem - dx * count) + minDx}px;}`;
                el.childNodes[1].style = `${count === selectedItem ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
            }
        })
        renderSlider();
        window.addEventListener("resize", renderSlider);
        return () => window.removeEventListener("resize", renderSlider);
    })

    const sliderRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(0);
    const [hoverArrow, setHoverArrow] = useState(null);
    const [touchStart, setTouchStart] = useState(null);
    const [touchMove, setTouchMove] = useState(null);

    let {width} = useViewport();
    if (width === 1600 && typeof window !== "undefined") width = window.innerWidth;
    if (width > maxWidth) width = maxWidth;

    let sliderImageWidth = Math.round(width / 2.58);
    if (sliderImageWidth > 620) sliderImageWidth = 620;
    const koefHeight = 0.72;
    if (width < 1400) dx = sliderImageWidth * 1.995;
    else dx = sliderImageWidth * 1.905;


    minDx = calcMinDx(width);

    const sliderImageHeight = sliderImageWidth * koefHeight;


    const nextHandler = () => {
        if (selectedItem === items.length - 1) return;
        sliderRef.current.childNodes.forEach((el, count) => {
            if (el.tagName === "DIV") return;
            el.style = `right: ${(dx * (selectedItem + 1) - count * dx) + minDx}px; ${count === selectedItem + 1 ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })

        setSelectedItem(selectedItem + 1)
    }

    const prevHandler = () => {
        if (selectedItem === 0) return;
        sliderRef.current.childNodes.forEach((el, count) => {
            if (el.tagName === "DIV") return;
            el.style = `right: ${(dx * (selectedItem - 1) - count * dx) + minDx}px; ${count === selectedItem - 1 ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
        setSelectedItem(selectedItem - 1)
    }

    const handleRadioClick = (index) => {
        sliderRef.current.childNodes.forEach((el, count) => {
            if (el.tagName === "DIV") return;
            if (!screens.lg) el.style = `right: ${count === index ? width * 0.1 / 2 : 1200 * index - 1200 * count}px; ${count === index ? 'z-index:2' : ''}`;
            else {
                el.style = `right: ${(dx * index - count * dx) + minDx}px;}`;
                el.childNodes[1].style = `${count === index ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
            }
        })
        setSelectedItem(index)
    }

    const handleMouseEnter = direction => {
        setHoverArrow(direction);
    }

    const handleMouseLeave = () => setHoverArrow(null);

    const handleTouchEnd = () => {
        if (!touchMove) return;
        if (touchStart > touchMove) {
            if (selectedItem === items.length - 1) return;
            handleRadioClick(selectedItem + 1)
        } else {
            if (selectedItem === 0) return;
            handleRadioClick(selectedItem - 1)
        }
        setTouchMove(null)
    }

    return (
        <SliderContainer maxWidth={maxWidth} height={sliderImageHeight * 1.4 + 250}
                         onTouchStart={(e) => setTouchStart(e.touches[0].pageX)}
                         onTouchMove={(e) => setTouchMove(e.touches[0].pageX)} onTouchEnd={handleTouchEnd}>
            <ContainerL>
                <PlusComponent text='проекты'/>
            </ContainerL>
            <SliderItems ref={sliderRef}>
                {
                    items.map((item, count) =>
                        <SliderItem height={sliderImageHeight} width={sliderImageWidth} key={count}>
                            <InfoCard width={sliderImageWidth * 0.645} height={sliderImageHeight}>
                                <InfoCardTitle width={sliderImageWidth * 0.645}>
                                    Название проекта <br></br>"{item.title}"</InfoCardTitle>
                                <InfoCardLine width={sliderImageWidth * 0.645} style={{color: "#FFFFFF"}}>
                                    {item.description}
                                </InfoCardLine>
                                <InfoCardLink to={'/projects/' + item.url}>
                                    ПОДРОБНЕЕ
                                    <img src={ArrowRightSmall} style={{marginLeft: "14px"}} alt="More"/>
                                </InfoCardLink>

                            </InfoCard>
                            <SliderImage height={sliderImageHeight} width={sliderImageWidth} src={item.img}/>
                        </SliderItem>
                    )
                }

                <TestDiv id="test" minDx={minDx} width={sliderImageWidth}>
                    <LineHelper width={sliderImageWidth * 0.645} height={sliderImageHeight}>
                      {
                        items && selectedItem !== 0 &&
                        <HideArrowDiv direction="left" width={sliderImageWidth * 0.645}>
                            <SliderNavLeft onClick={prevHandler}>
                                <Button type="primary" onMouseEnter={() => handleMouseEnter("left")}
                                        onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                    <LeftArrow color={hoverArrow === "left" && "#4285F4"}/>
                                </Button>
                            </SliderNavLeft>
                        </HideArrowDiv>
                      }
                        
                    </LineHelper>

                    {
                      items && selectedItem !== items.length - 1 && 
                      <HideArrowDiv direction="right" width={sliderImageWidth}>
                        <SliderNavRight onClick={nextHandler}>
                            <Button type="primary" onMouseEnter={() => handleMouseEnter("right")}
                                    onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                <RightArrow color={hoverArrow === "right" && "#4285F4"}/>
                            </Button>
                        </SliderNavRight>
                      </HideArrowDiv>
                    }
                    
                </TestDiv>
            </SliderItems>


            {
                !screens.lg &&
                <RadioControl>
                    {
                        items.map((item, count) =>
                            <RadioDiv selected={count === selectedItem} key={count}
                                      onClick={() => handleRadioClick(count)}/>
                        )
                    }
                </RadioControl>
            }


        </SliderContainer>
    )
}

export default CarouselProjects;
