import React from 'react'
import Img1 from '../../assets/Vector.svg'
import Img2 from '../../assets/ruckus.svg'
import Img3 from '../../assets/came.svg'
import Img4 from '../../assets/Frame.svg'
import Img5 from '../../assets/Group.svg'
import Img6 from '../../assets/Group2.svg'
import Img7 from '../../assets/BPT.svg'
import Img8 from '../../assets/Hikvision.svg'
import Img9 from '../../assets/logo1.svg'
import Img10 from '../../assets/path23.svg'
import Img11 from '../../assets/Vector2svg.svg'
import Img12 from '../../assets/iRidium.svg'
import '../layout.css'
import styled from 'styled-components'
import PlusComponent from "../../ui/plusComponent";


const DATA = [
    {
        img: <Img1 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img2 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img3 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img4 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img5 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img6 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img7 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img8 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img9 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img10 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img11 className={'activeblue adaptive-svg-partner'} />,
    },
    {
        img: <Img12 className={'activeblue adaptive-svg-partner'} />,
    },

]

const ContainerItem = styled.div`

    height:195px;
    border: 1px solid #1C1A1A;
    box-sizing: border-box;
    display:flex;
    justify-content:center;
    align-items:center;
    @media(max-width:368px){
        max-width:150px;
        height:120px;
    }
`;
const Container = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top:110px;
    @media(max-width:1268px){
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media(max-width:968px){
        grid-template-columns: 1fr 1fr 1fr ;
    }
    @media(max-width:768px){
        display:none;
        max-width:100%;
    }

`;


const ContainerMobile = styled.div`
    display:none;
    @media(max-width:768px){
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top:110px;
        margin:10px;
        max-width:100%;
    }
    @media(max-width:568px){
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top:110px;
        margin:10px;
    }
    @media(max-width:368px){
        display:grid;
        grid-template-columns: 1fr 1fr;
        margin-top:110px;
        margin:10px;
    }
`;

const Div = styled.div`
  margin-top: 110px;
  margin-bottom: 150px;
  @media(max-width: 968px)
  {
    margin-bottom: 110px;
  }
`;

const Partners = () => {
    return (
        <Div>
            <PlusComponent text='Партнеры'/>
            <Container>
                {DATA.map((item, index) => {
                    return (
                        <ContainerItem key={index}>
                            <a href={item.link}>{item.img}</a>
                        </ContainerItem>
                    )
                })}
            </Container>
            <ContainerMobile>
                {DATA.map((item, index) => {
                    return (
                            <ContainerItem key={index}>
                               {item.img}
                            </ContainerItem>
                        

                )
                })}
            </ContainerMobile>
        </Div>
    )
}

export default Partners
